import { Controller } from '@hotwired/stimulus'
import moment from 'moment'

export default class extends Controller {
  static targets = ['field']

  connect() {
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
    console.log(currentTime)
    this.fieldTarget.value = currentTime
  }
}
